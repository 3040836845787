import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
`;

export const Content = styled.div`
  overflow: auto;
  flex: 1 1 auto;
  height: 100%;
  padding: 40px 30px;
  margin-top: 64px;
  position: relative;
`;
