import React, { useState } from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { useStyles, MenuList, MenuLink, Line } from './styles';
import { menuItems } from './config';
import Link from '@material-ui/core/Link';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth';
import ArrowDropDown from '@material-ui/icons/ExpandMore';
import ArrowDropUp from '@material-ui/icons/ExpandLess';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import BrushIcon from '@material-ui/icons/Brush';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';

const Sidebar = () => {
  const [menu, setMenu] = useState(menuItems);
  const classes = useStyles();
  const pathName = window.location.pathname;
  const { logout } = useAuth();
  const auth = useAuth();
  const permission = auth.user.email
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };
  console.log(auth, 'auth')
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <img src="/logo.svg" />
      </div>
      <Line />
      <MenuList>




        {menu.map(({ title, path, Icon, options, show ,privates}, index) => (
        <>
          {options ? (
            // dados mocados para futura feature para deixar essas informaçoes inseridas dinamicamentes 
              <>
          {permission == ('pioneirosp@cartazimpresso.com.br' ||'matrixprint@matrixprint.com.br' || 'supermercadoelimar@cartazimpresso.com.br' || 'pareaqui@cartazimpresso.com.br' || 'baratinho@cartazimpresso.com.br'|| 'supermercadopioneirosp@gmail.com') && 


                <MenuLink
                button
                active={pathName === path}
                  onClick={() =>
                    setMenu(
                      menu.map((m, i) => {
                        if (i === index) {
                          return { ...m, show: !m.show };
                        }
                        return m;
                      })
                      )
                    }
                >

                  <ListItemIcon>{show ? <ArrowDropUp /> : <ArrowDropDown />}</ListItemIcon>
                  <ListItemText primary={title} />
                </MenuLink>
                  } 
                {show && (

                  <div style={{ marginLeft: 0 }}>
                    {options.map(({ title, path, privates }) => (

                        <Link key={path} href={path}>
                          <MenuLink button active={pathName === path}>
                            <ListItemText primary={title} />
                          </MenuLink>
                        </Link>

                    ))}
                  </div>
                )}
              </>
            ) : (
               
         <Link key={path} href={path}>
                <MenuLink button active={pathName === path}>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={title} />
                </MenuLink>
              </Link> 
          )}
        </>
        ))} 
        <MenuLink button onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </MenuLink>
      </MenuList>
    </Drawer>
  );
};

export default Sidebar;
