import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';
import TextField from '@material-ui/core/TextField';

const Input = (props) => {
  const { label, name, error, rows, ...other } = props;
  return (
    <Wrapper>
      <FormControl variant="outlined">
        <TextField
          {...other}
          native
          fullWidth
          variant="outlined"
          name={name}
          multiline
          
          rows={rows}
          label={label}
        />
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </Wrapper>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  rows: PropTypes.number
};

export default Input;
