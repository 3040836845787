import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
  /* background: linear-gradient(180deg, #00224d 70%, #ee7763 100%); */
  flex:1;
  display:flex;
  height: 100%;
`;

export const Form = styled.form`
  background: #f6f4f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 20px;
  max-width: 350px;
  gap: 1rem;

  h1 {
  }
`;

export const Logo = styled.img`
  width: 287px;
  height: 66px;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

export const Img = styled.img`
  /* display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 100%; */
    width: 100%;
  height: 100%;
    /* opacity: 0.6; */
`;

export const Title = styled(Typography)`
  font-size: 34px;
  line-height: 40px;
  color: #000000;
  font-weight: bolder;
`;
export const TextResetPassWord = styled(Typography)`
   font-size: 18px;
  line-height: 26px;
  color: #fff;
  font-weight: bolder;
`;

export const LoginButton = styled.button`
  width: 147px;
  height: 38px;
  border: none;
  background-color: #00224d;
  color: #ffffff;
  font-size: 18px;
  line-height: 21px;
  margin-top: 16px;
  cursor: pointer;
`;

export const Views = styled.div`
 position: relative;
 flex:2;
 background-color: #00224d;
    overflow: hidden;
`;
