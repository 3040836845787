import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Authentication from '../../../pages/Authentication';
import useAuth from '../../../shared/hooks/useAuth';

const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated || auth.user.userStatus === 'FIRST_ACCESS') {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Authentication />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <div style={{ display: 'flex' }}>{children}</div>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
