import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const PrintContext = createContext({});

export const PrintProvider = (props) => {
  const { children } = props;
  const [allPosters, setAllPosters] = useState([]);
  const [selectedPosters, setSelectedPosters] = useState([]);

  return (
    <PrintContext.Provider
      value={{
        allPosters,
        setAllPosters,
        selectedPosters,
        setSelectedPosters
      }}
    >
      {children}
    </PrintContext.Provider>
  );
};

PrintProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export function usePrint() {
  const context = useContext(PrintContext);

  return context;
}
