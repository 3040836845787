import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useLoader } from '../../providers/loader';

const Loader = () => {
  const { isLoading } = useLoader();

  return (
    isLoading && (
      <Box
        style={{
          zIndex: 9999,
          position: 'absolute',
          margin: '0 auto',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    )
  );
};

export default Loader;
