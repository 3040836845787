import React from 'react';
import { Formik } from 'formik';
import { Form, Title, LoginButton,InputLogin } from './styles';
import PasswordInput from '../../../../shared/components/PasswordInput';
import useAuth from '../../../../shared/hooks/useAuth';
import { toast } from 'react-toastify';

const FirstAccess = () => {
  const { firstAccess } = useAuth();

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: ''
      }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (values.newPassword === values.oldPassword) {
          const notify = () => toast.error('A nova senha deve ser diferente da senha atual');
          notify();
        } else if (values.newPassword === '' || values.oldPassword === '') {
          const notify = () => toast.error('Preencha os campos corretamente');
          notify();
        } else if (values.newPassword !== values.newPasswordRepeat) {
          const notify = () => toast.error('Repita a nova senha corretamente');
          notify();
        } else {
          try {
            await firstAccess(values.oldPassword, values.newPassword);
            setStatus({ success: true });
            setSubmitting(false);
          } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
     
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched }) => (
         <>
        <Form noValidate onSubmit={handleSubmit}>
          <Title>Primeiro Acesso</Title>
       
          <InputLogin
            placeholder='Senha atual'
            type="password"
            name="oldPassword"
            onChange={handleChange}
            onBlur={handleBlur}

            // onBlur={handleBlur}
          />
          {/* <PasswordInput
            label="Senha atual"
            name="oldPassword"
            type="email"
            style={{color:'red'}}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.oldPassword && errors.oldPassword)}
            helperText={touched.oldPassword && errors.oldPassword}
          /> */}
          <p style={{ color: 'red' }}> {touched.oldPassword && errors.oldPassword}</p>
          <InputLogin
            placeholder='Nova senha'
            type="password"
            name="newPassword"
            onChange={handleChange}
            onBlur={handleBlur}

            // onBlur={handleBlur}
          />

          {/* <PasswordInput
            label="Nova senha"
            name="newPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          /> */}
          <p style={{ color: 'red' }}> {touched.newPassword && errors.newPassword}</p>

          <InputLogin
            placeholder='Repita a nova senha'
            type="password"
            name="newPasswordRepeat"
            onChange={handleChange}
            onBlur={handleBlur}

            // onBlur={handleBlur}
        />
       
          <p style={{ color: 'red' }}> {touched.newPasswordRepeat && errors.newPasswordRepeat}</p>

          <LoginButton type="submit" disabled={isSubmitting}>
            Mudar senha
          </LoginButton>
        </Form>
      </>
      )}

    </Formik>
  );
};

export default FirstAccess;
