import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  // baseURL: 'https://backenddev.matrixprint.com.br/',
  // baseURL: 'https://backend.matrixprint.com.br/',

  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  }
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('@matrixPrint:accessToken');

  if (token) {
    config.headers = config.headers ? config.headers : {};
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Algo de errado aconteceu')
);

export default axiosInstance;
