import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { HeaderMenu, Content, Navigator, Controllers, UserArea } from './styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import useAuth from '../../../../hooks/useAuth';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import routes from '../../../../../Routes/routes';

const Header = () => {
  const auth = useAuth();
  const path = document.URL.split('/').pop(-1);
  const [pageName, setPageName] = useState('');

  useEffect(() => {
    routes[1].children.forEach((element) => {
      if (element.path?.length > 0) {
        if (element.path === `/${path}`) {
          setPageName(element.name);
        }
      }
    });
  });

  return (
    <HeaderMenu position="fixed">
      <Content>
        <Navigator
          separator={pageName?.length > 0 && <NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link color="inherit" href="/">
            Dashboard
          </Link>
          <Typography color="textPrimary">{pageName}</Typography>
        </Navigator>
        <Controllers>
          <UserArea>
            <Typography color="textPrimary">
              Bem vindo,{' '}
              <b>
                {auth?.user?.fullname?.length > 1
                  ? `${auth?.user?.fullname}`
                  : auth?.user?.phantasyName?.length > 1
                  ? `${auth?.user?.phantasyName}`
                  : 'Cliente'}
              </b>
            </Typography>
            <AccountCircleIcon />
          </UserArea>
        </Controllers>
      </Content>
    </HeaderMenu>
  );
};

export default Header;
