import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const LoaderContext = createContext({});

export const LoaderProvider = (props) => {
  const { children } = props;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoaderContext.Provider
      value={{
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

LoaderProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export function useLoader() {
  const context = useContext(LoaderContext);

  return context;
}
