import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from '../lib/axios';
import { toast } from 'react-toastify';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  resetPasswordStatus: false,
  user: null
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('@matrixPrint:accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('@matrixPrint:accessToken');
    window.localStorage.removeItem('@matrixPrint:user');

    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  FIRST_ACCESS: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  RESET_PASSWORD: (state) => ({
    ...state,
    resetPasswordStatus: true
  }),
  RESET_PASSWORD_STATUS: (state) => ({
    ...state,
    resetPasswordStatus: false
  }),
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  firstAccess: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  resetPasswordd: () => Promise.resolve(),
  resetStatusPassword: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (username, password) => {
    try {
      await axios
        .post('/authenticate', {
          username,
          password
        })
        .then((loginResponse) => {
          const { jwtToken } = loginResponse.data;
          setSession(jwtToken);
          axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;

        })
        .then(async () => {
          await axios.get('/users/me').then((response) => {
            const user = response.data;
            window.localStorage.setItem('@matrixPrint:user', JSON.stringify(user));
            dispatch({
              type: 'LOGIN',
              payload: {
                user
              }
            });
          });
        });
    } catch (err) {
      if (err.status === 401) {
        const notify = () => toast.error('E-mail ou senha incorreta');
        notify();
      } else if (err.status === 404) {
        const notify = () => toast.error('Usuário não encontrado');
        notify();
      } else {
        const notify = () =>
          toast.error(
            'Ocorreu um erro por favor tente novamente ou entre em contato com nosso suporte'
          );
        notify();
      }
    }
  };

  const resetStatusPassword = async () => {
 
    dispatch({
      type: 'RESET_PASSWORD_STATUS',
      payload: {
        resetPasswordStatus: false
      }
    });

  };

  const resetPasswordd = async (email, password) => {
    try {
       
      
      let emaillll = email
      const res =  await axios.patch('/users/request-password',{ email:emaillll}  )
      .then(()=>{
        const ress =  axios.patch('/users/reset-password', {
          email,
          password
        })
        .then(() => {
          const notify = () => toast.success('Senha alterada com sucesso');
          notify();
          dispatch({
            type: 'RESET_PASSWORD',
            payload: {
              resetPasswordStatus: true
            }
          });
        })


      })
      .catch((err)=>{console.log({err},'....')})
   
       
    } catch (err) {


      if (err.status === 401) {
        const notify = () => toast.error('Ocorreu um erro ao trocar sua senha tente novamente');
        notify();
      } else if (err.status === 404) {
        const notify = () => toast.error('Ocorreu um erro ao trocar sua senha tente novamente');
        notify();
      } else {
        const notify = () =>
          toast.error(
            'Ocorreu um erro por favor tente novamente ou entre em contato com nosso suporte'
          );
        notify();
      }
    }
  };

  const firstAccess = async (oldPassword, newPassword) => {
    try {
      await axios
        .post('/users/new-password', {
          oldPassword,
          newPassword
        })
        .then(async () => {
          await axios.get('/users/me').then((response) => {
            const user = response.data;
            window.localStorage.setItem('@matrixPrint:user', JSON.stringify(user));
            dispatch({
              type: 'FIRST_ACCESS',
              payload: {
                user
              }
            });
          });
        });
      const notify = () => toast.success('Senha alterada com sucesso');
      notify();
    } catch (err) {
      if (err.status === 401) {
        const notify = () => toast.error('Você não tem permissão para acessar este recurso');
        notify();
      } else if (err.status === 404) {
        const notify = () => toast.error('Usuário não encontrado');
        notify();
      } else if (err.status === 406) {
        const notify = () => toast.error('Senha atual não corresponde');
        notify();
      } else if (err.status === 409) {
        const notify = () => toast.error('Nova senha não pode ser igual à atual');
        notify();
      } else {
        const notify = () =>
          toast.error(
            'Ocorreu um erro por favor tente novamente ou entre em contato com nosso suporte'
          );
        notify();
      }
    }
  };

  const logout = async () => {
    await axios.post('/users/logout');
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  
  const register = async (email, password) => {
    const response = await axios.post('/users', {
      email,
      password
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('@matrixPrint:accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('@matrixPrint:accessToken');

        if (accessToken) {
          setSession(accessToken);

          const response = await axios.get('/users/me');
          const user = response.data;
          window.localStorage.setItem('@matrixPrint:user', JSON.stringify(user));

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        firstAccess,
        logout,
        resetPasswordd,
        resetStatusPassword,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
