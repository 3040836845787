import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Toolbar from '@material-ui/core/Toolbar';

export const HeaderMenu = styled(AppBar)`
  width: calc(100% - 255px);
  margin-left: 255px;
  background-color: #f6f4f5;
  box-shadow: unset;
  border-bottom: 1px solid #c4c4c4;
`;

export const Content = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Navigator = styled(Breadcrumbs)`
  a,
  li,
  svg {
    color: #000 !important;
  }
  p {
    color: #ee7763;
  }
`;

export const Controllers = styled.div`
  display: flex;
  align-items: center;
`;

export const UserArea = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #000;
  padding-left: 40px;
`;
