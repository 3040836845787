import React from 'react';
import GlobalStyles from './styles/GlobalStyles';
import { ThemeProvider } from '@material-ui/core/styles';
import { LoaderProvider } from './shared/providers/loader';
import { useRoutes } from 'react-router-dom';
import Loader from './shared/components/Loader';
import { PrintProvider } from './shared/providers/print';
import useAuth from './shared/hooks/useAuth';
import SlashScreen from './shared/components/SlashScreen';
import { theme } from './styles/theme';
import routes from './Routes/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const content = useRoutes(routes);
  const auth = useAuth();
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        draggable
      />
      <GlobalStyles />
      <PrintProvider>
        {auth.isInitialized ? (
          <LoaderProvider>
            <Loader />
            {content}
          </LoaderProvider>
        ) : (
          <SlashScreen />
        )}
      </PrintProvider>
    </ThemeProvider>
  );
};

export default App;
