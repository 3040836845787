import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Form = styled.form`
 border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 350px;
  gap: 1rem;

  h1 {
  }
`;

export const Title = styled(Typography)`
  font-size: 18px;
  line-height: 26px;
  color: #fff;
  font-weight: bolder;
`;
export const LoginButton = styled.button`
  width: 240px;
  height: 48px;
  border: none;
  background-color: #ee7763;
  color: #ffffff;
  border-radius: 8px;
  font-size: 18px;
  line-height: 21px;
  margin-top: 10px;
  margin-bottom: 14px;
  cursor: pointer;
`;
export const InputLogin = styled.input`
  color:#75759d;
  width: 380px;
  border-radius: 8px;
  height: 48px;
  font-size: 18px;
  background-color: #fff;
  border-color: #00224d;
  padding-left: 10px;
`;

styled;
