import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#00224D'
    },
    secondary: {
      main: '#EE7763'
    }
  }
});
