import React from 'react';
import { Outlet } from 'react-router-dom';
import Main from './components/Main';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { Wrapper } from './styles';

const DashboardLayout = () => {
  return (
    <Wrapper>
      
      <Sidebar />
      <Header />
      <Main>

        <Outlet />
      </Main>
    </Wrapper>
  );
};

export default DashboardLayout;
