import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

const drawerWidth = 255;

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.mixins.toolbar
  },
  listItem: {
    color: '#ffffff',
    height: '61px',
    margin: '12.5px 0'
  }
}));

export const MenuLink = styled(ListItem)`
  font-weight: 700;
  height: 30px;
  margin: 35px 0;
  padding: 20px 20px 20px 40px;
  svg {
    color: ${({ active }) => (active ? '#ee7763' : '#fff')};
  }
  color: ${({ active }) => (active ? '#ee7763' : '#fff')};
  border-right: ${({ active }) => (active ? '5px solid #ee7763' : 'unset')};
  :hover {
    background-color: unset;
    color: #ee7763;
    svg {
      color: #ee7763;
    }
  }
`;

export const Line = styled(Divider)`
  background-color: #c4c4c4;
`;

export const MenuList = styled(List)`
  a {
    text-decoration: none;
  }
`;
