import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Title, LoginButton, InputLogin } from './styles';
import Input from '../../../../shared/components/Input';
import PasswordInput from '../../../../shared/components/PasswordInput';
import useAuth from '../../../../shared/hooks/useAuth';
import { Box, FormHelperText,CircularProgress } from '@material-ui/core';

const Login = () => {
  const [loading, setLoading] = useState(false)
  const { login, resetPasswordd } = useAuth();

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .email('O e-mail precisa ser válido')
          .max(255)
          .required('Por favor insira um e-mail'),
        password: Yup.string().max(255).required('Por favor insira uma senha')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true);
        setLoading(true)
        try {
          await login(values.username, values.password);
          setStatus({ success: true });
          setLoading(false)

        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setLoading(false)

        }
        setSubmitting(false);
        setLoading(false)

      }}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Title>Entrar</Title>
          <InputLogin
            placeholder='E-mail'
            type="email"
            name="username"
            onChange={handleChange}
            // onBlur={handleBlur}
            value={values.username}
          /> 
          <p style={{ color: 'red' }}> {touched.username && errors.username}</p>
          <InputLogin
            name="password"
            placeholder='Senha'
            type="password"
            onChange={handleChange}
            // onBlur={handleBlur}
            value={values.password}
          />
          <p style={{ color: 'red' }}>{touched.password && errors.password}</p>

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                <div>{errors.submit}</div>
              </FormHelperText>
            </Box>
          )}


          <LoginButton type="submit" disabled={isSubmitting}>
      
            {loading ?   
              <CircularProgress  size={25}/>:
              'Entrar'
            }
          </LoginButton>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
