import React, { useEffect,useState,useReducer } from 'react';
import { Grid } from '@material-ui/core';
import { Wrapper, Logo, Img, Views,TextResetPassWord } from './styles';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import FirstAccess from './components/FirstAccess';
import useAuth from '../../shared/hooks/useAuth';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Authentication = () => {

  const auth = useAuth();
  const {resetStatusPassword} = useAuth();
  const [resetPasswordState, setResetPasswordStatus] = useState(false)


  useEffect(()=>{
    setResetPasswordStatus(false)

    resetStatusPassword()

  },[auth.resetPasswordStatus])


  return (
    <Wrapper>
      <div style={{ cursor: 'pointer', padding: 10, backgroundColor: "#00224d", flex: 1, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {resetPasswordState == true &&

          <div onClick={() => { setResetPasswordStatus(false) }} style={{ justifyContent: 'flex-start', alignItems: 'flex-start', position: 'relative', right: 130, bottom: 50}}>
            <div style={{ alignItems: 'center', justifyContent: 'center',flexDirection:'row',display:"flex" }}>
              <div>
                <ArrowBackIcon style={{color:'#fff',fontSize:25}}  />
              </div>
              <span  style={{color:'#fff',marginLeft:6,fontSize:22}}>
                Voltar
              </span>
            </div>
          </div>
        }
        <div style={{ marginBottom: 30, alignItems: 'flex-start', justifyContent: "flex-start", display: 'flex' }}>


          <div style={{ bot: 50 }}>

            <img src="/img/footer-logo.png" />
          </div>
        </div>

        <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'flex-start' }}>
          {(!auth.isAuthenticated && resetPasswordState == false) && <Login />}
          {resetPasswordState == true && <ResetPassword />}
          {auth.user?.userStatus === 'FIRST_ACCESS' && <FirstAccess />}
          {(auth.user?.userStatus !== 'FIRST_ACCESS' && resetPasswordState == false) &&
            <div style={{ marginTop: 20 }} onClick={() => { setResetPasswordStatus(true) }}>

              <TextResetPassWord>Equeceu sua senha?</TextResetPassWord>
            </div>
          }
        </div>

      </div>
      <Views>
        <Img src="/img/cartaz-personalizado.png" />

        {/* <Img src="/img/footer-logo.png" /> */}
      </Views>

      <div></div>
      {/* <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
          {!auth.isAuthenticated && <Login />}
          {auth.user?.userStatus === 'FIRST_ACCESS' && <FirstAccess />}
        <Grid item sm={8} justifyContent="center">
          <Logo src="logo.svg" />
          <Img src="/img/cartaz-personalizado.png" />
        </Grid>
       
      </Grid> */}
    </Wrapper>
  );
};
export default Authentication;
