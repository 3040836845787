import DashboardIcon from '@material-ui/icons/Dashboard';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import BrushIcon from '@material-ui/icons/Brush';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

export const menuItems = [
  {
    title: 'Dashboard',
    path: '/',
    Icon: DashboardIcon
  },
  {
    title: 'Novo Cartaz',
    path: '/novo-cartaz',
    Icon: NoteAddOutlinedIcon
  },
  // {
  //   title: 'Tabloide',
  //   path: '/tabloides',
  //   Icon: NoteAddOutlinedIcon
  // },
  // {
  //   title: 'Layout',
  //   path: '/layouts',
  //   Icon: NoteAddOutlinedIcon,
  //   privates: true
  // },
  {
    title: 'Tabloide',
    path: '/tabloide',
    options: [
      {
        title: 'Layout',
        path: '/layouts',
        privates: true
      },
      {
        title: 'Tabloide',
        path: '/tabloides'
      }
    ],
    show: false,
    Icon: NoteAddOutlinedIcon
  },
  {
    title: 'Meus Cartazes',
    path: '/meus-cartazes',
    Icon: InsertDriveFileOutlinedIcon
  },
  {
    title: 'Campanhas',
    path: '/campanhas',
    Icon: BrushIcon
  },
  {
    title: 'Produtos',
    path: '/produtos',
    Icon: ShoppingCartIcon
  },
  {
    title: 'Configurações',
    path: '/configuracoes',
    Icon: SettingsIcon
  }
];
