import React, { useState ,useReducer} from 'react';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Title, LoginButton, InputLogin } from './styles';
import Input from '../../../../shared/components/Input';
import PasswordInput from '../../../../shared/components/PasswordInput';
import useAuth from '../../../../shared/hooks/useAuth';
import { Box, FormHelperText, CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';

const ResetPassword = () => {

  const [loading, setLoading] = useState(false)
  const {resetPasswordd } = useAuth();

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        passwordConfirmation: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .email('O e-mail precisa ser válido')
          .max(255)
          .required('Por favor insira um e-mail')

          ,
        password: Yup.string().max(255).required('Por favor insira uma senha')
        .min(6, 'A senha deve conter 6 dígitos.')
        ,
        passwordConfirmation: Yup.string()
        .min(6, 'A senha deve conter 6 dígitos.')
          .oneOf([Yup.ref('password'), null], 'As senhas inseridas não conferem.')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true);
        setLoading(true)
        console.log(values)
        try {
          await resetPasswordd(values.username, values.password)
     
          setStatus({ success: true });
          setLoading(false)


        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setLoading(false)

        }
        setSubmitting(false);
        setLoading(false)

      }}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Title>Troque sua senha</Title>
          <InputLogin
            placeholder='E-mail'
            type="email"
            name="username"
            onChange={handleChange}
            // onBlur={handleBlur}
            value={values.username}
          />
          <p style={{ color: 'red' }}> {touched.username && errors.username}</p>
          <InputLogin
            name="password"
            placeholder='Nova senha'
            type="password"
            onChange={handleChange}
            // onBlur={handleBlur}
            value={values.password}
          />
          <p style={{ color: 'red' }}>{touched.password && errors.password}</p>
          <InputLogin
            name="passwordConfirmation"
            placeholder='Confirme a nova senha'
            type="password"
            onChange={handleChange}
            // onBlur={handleBlur}
            value={values.passwordConfirmation}
          />
          <p style={{ color: 'red' }}>{touched.passwordConfirmation && errors.passwordConfirmation}</p>

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                <div>{errors.submit}</div>
              </FormHelperText>
            </Box>
          )}


          <LoginButton type="submit" disabled={isSubmitting}>

            {loading ?
              <CircularProgress size={25} /> :
              'Mudar senha'
            }
          </LoginButton>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassword;
